import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import MapComponent from "../Map";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";
import {GoogleMap} from "@react-google-maps/api";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = {
    lat: -3.745,
    lng: -38.523
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  const d = new Date()
  const year = d.getFullYear()

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={12} md={12} sm={12} xs={12}>
              <Empty />
              <Title>{t("ساعات الدوام")}</Title>
              <Para>يومياً ما عدا الجمعة</Para>
              <Para>٨ صباحاً - ٧ مساءً</Para>
              <Empty/>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Empty />
              <Title>{t("العنوان")}</Title>
              <Para>فلسطين، رام الله والبيرة</Para>
              <Para>بيتونيا، مقابل محطة عطاري وعليان</Para>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={12} md={12} sm={12} xs={12}>
              <Title>{t("للتواصل")}</Title>
              <Para>+970 595333344</Para>
              <Para>+970 599664556</Para>
              <Para>info@abuobeid.com</Para>
            </Col>
          </Row>
          <Empty/>
          <Row justify="space-between">
            <MapComponent/>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <Col lg={24} md={24} sm={24} xs={24}>
              <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                    src="logo.svg"
                    aria-label="homepage"
                    width="101px"
                    height="64px"
                />
                <span style={{fontSize: "smaller"}}>&copy; {year} Abu Obeid Trading Company -
                Designed By: <a href="https://aplus-technologies.com">Aplus Technologies</a></span>
              </LogoContainer>
            </NavLink>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
